export const menuItems = {

  // header menu items
  header: [
    {
      label: "Home",
      to: "/",
      dropdown: false,
      useIcon: true,
    },
    {
      label: "About",
      to: "",
      dropdown: true,
      items: [
        { label: "Local Organising Committee", to: "/local-organising-committee" },
        { label: "International Scientific Advisory Committee", to: "/international-scientific-advisory-committee" },
        { label: "Previous International Vasculitis Workshops", to: "/previous-international-vasculitis-workshops" }, 
        { label: "Promotional Toolkit", to: "/promotional-toolkit" },
        { label: "Sustainability", to: "/sustainability" },
        { label: "Latest News", to: "/latest-news" },
      ],
    },
    {
      label: "Registration",
      to: "",
      dropdown: true,
      items: [
        { label: "Registration", to: "/registration" },
        { label: "Accommodation", to: "/accommodation" },
      ],
    },
    {
      label: "Program",
      to: "",
      dropdown: true,
      items: [
        { label: "Call for Abstracts", to: "/call-for-abstracts" },
        { label: "Program", to: "/program-full" },
      ],
    },
    {
      label: "Destination",
      to: "",
      dropdown: true,
      items: [
        { label: "Visa Information", to: "/visa-information" },
        { label: "Workshop Venue", to: "/workshop-venue" },
        { label: "Melbourne Bucket List", to: "/melbourne-bucket-list" },
        { label: "LOC Melbourne Guides", to: "/loc-melbourne-guides" },
        { label: "Visit Victoria", to: "/visit-victoria" },
        { label: "Amazing Australasia", to: "/amazing-australasia" },
        { label: "Transport Tips", to: "/transport-tips" },
      ],
    },
    {
      label: "Sponsorship",
      to: "",
      dropdown: true,
      items: [
        { label: "Sponsorship Information & Prospectus", to: "/sponsorship-info" },
        { label: "Sponsors & Exhibitors", to: "/sponsors-and-exhibitors" },
      ],
    },
    {
      label: "Information",
      to: "",
      dropdown: true,
      items: [
        { label: "Privacy Policy", to: "/privacy-policy" },
        { label: "Contact Us", to: "/contact" },
      ],
    },
  ],

  // footer menu items
  footer: [
    // {
    //   label: "Home/About",
    //   to: "/",
    //   dropdown: true,
    //   items: [
    //     { label: "Welcome Message", to: "/#welcome" },
    //     { label: "Key Dates", to: "/#key-dates" },
    //     { label: "Join the Mailing List", to: "/#mailing-list" },
    //     { label: "Acknowledgement of Country", to: "/#aoc" },
    //     // { label: "Promotional Toolkit", to: "/promotional-toolkit" },
    //   ],
    // },
    {
      label: "About",
      to: "",
      dropdown: true,
      items: [
        { label: "Promotional Toolkit", to: "/promotional-toolkit" },
      ],
    },
    {
      label: "Program",
      to: "",
      dropdown: true,
      items: [
        { label: "Program Overview", to: "/program" },
      ],
    },
    {
      label: "Destination",
      to: "",
      dropdown: true,
      items: [
        { label: "Workshop Venue", to: "/workshop-venue" },
        { label: "Melbourne Bucket List", to: "/melbourne-bucket-list" },
        { label: "Visit Victoria", to: "/visit-victoria" },
        { label: "Amazing Australasia", to: "/amazing-australasia" },
        { label: "Transport Tips", to: "/transport-tips" },
        { label: "Visa Information", to: "/visa-information" },
      ],
    },
    {
      label: "Sponsorship",
      to: "",
      dropdown: true,
      items: [
        { label: "Sponsorship Information & Prospectus", to: "/sponsorship-info" },
        // { label: "Sponsors & Exhibitors", to: "/sponsors-and-exhibitors" },
      ],
    },
    {
      label: "Information",
      to: "",
      dropdown: true,
      items: [     
        { label: "Privacy Policy", to: "/privacy-policy" },
        { label: "Contact Us", to: "/contact" },
      ],
    },
  ],

  // social menu items
  // social: [
  //   {
  //     name: "facebook",
  //     url: "#",
  //     screenReader: "Follow us on Facebook",
  //   },
  //   {
  //     name: "linkedin",
  //     url: "#",
  //     screenReader: "Follow us on LinkedIn",
  //   },
  //   {
  //     name: "instagram",
  //     url: "#",
  //     screenReader: "Follow us on Instagram",
  //   },
  //   {
  //     name: "twitter",
  //     url: "#",
  //     screenReader: "Follow us on Twitter",
  //   },
  // ]
};
