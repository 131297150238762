import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { getPageData } from '@/data/pageData';
import { LayersIcon} from "@/components/icons/icons";
import { MessageSquareText, Landmark, MessageCircleQuestion } from "lucide-react";
import FeatureCard from "@/components/cards/FeatureCard";
import FWNavBar from "@/components/navbar/FullWidthNavBar";
import InLineNavBar from "@/components/navbar/InLineNavBar";
import ScrollRevealWrapper from "@/components/utility/ScrollRevealWrapper";
import config from '@/utils/config';

const Header = () => {
  const location = useLocation();
  const { backgroundImage, heading } = getPageData(location.pathname);
  const isHomepage = location.pathname === '/';

  let featureSection;

  if (isHomepage) {
    featureSection = (
      <section className="py-6 md:py-20 relative z-10">
        <div className="container mx-auto grid grid-cols-1 gap-6 px-6 md:grid-cols-2 lg:grid-cols-4">
        <ScrollRevealWrapper>
            <FeatureCard
              Icon={MessageSquareText}
              title="Program Overview"
              description="Get an in-depth look at our sessions, speakers, and key topics throughout the workshop."
              bgCustom="bg-gradient-to-tr from-c4 to-c1"
              link="/program"
            />
          </ScrollRevealWrapper>
          <ScrollRevealWrapper delay={100}>
            <FeatureCard
              Icon={Landmark}
              title="Call for Abstracts"
              description="Abstracts are invited to be submitted for the 22nd IVW 2026."
              bgCustom="bg-gradient-to-tr from-c1 to-c2"
              link="/call-for-abstracts"
            />
          </ScrollRevealWrapper>
          <ScrollRevealWrapper delay={200}>
            <FeatureCard
              Icon={LayersIcon}
              title="Sponsorship Information"
              description="Discover sponsorship opportunities that support the workshop and your brand."
              bgCustom="bg-gradient-to-tr from-c2 to-c3"
              link="/sponsorship-info"
            />
          </ScrollRevealWrapper>
          <ScrollRevealWrapper delay={300}>
            <FeatureCard
              Icon={MessageCircleQuestion}
              title="Registration"
              description="Registration for the 22nd IVW and associated events is now open."
              bgCustom="bg-gradient-to-tr from-c3 to-c5"
              link="/registration"
            />
          </ScrollRevealWrapper>
        
        </div>
      </section>
    );
  }

  useEffect(() => {
    const fwNavbar = document.getElementById("fw-navbar");
    const ilNavbar = document.getElementById("il-navbar");

    if (fwNavbar) {
      document.body.classList.add("fw-navbar");
    } else if (ilNavbar) {
      document.body.classList.add("il-navbar");
    }

    return () => {
      document.body.classList.remove("fw-navbar");
      document.body.classList.remove("il-navbar");
    };
  }, []);

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-30"></div>
        {config.inlineNavbar ? <InLineNavBar /> : <FWNavBar />}

        <section className="relative z-10">
          <div className="container mx-auto grid grid-cols-1 gap-12 px-6 min-h-[300px] lg:min-h-[350px]">
            <div className="space-y-6 flex justify-end items-end">
              <h1
                className={`text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-6xl text-end max-w-[800px] mb-6 lg:mb-10`}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              {/* <p className="text-white" dangerouslySetInnerHTML={{ __html: description }} /> */}
            </div>
            {/* <div className="relative rounded-lg">
              {location.pathname === "/" && (
                <VideoPlayer video="sample.mp4" capture="capture.png" />
              )}
            </div> */}
          </div>
        </section>

        {config.headerFeatureBoxes ? featureSection : null }
      </header>
    </>
  );
}

export default Header;

{/* <ScrollRevealWrapper>
            <FeatureCard
              Icon={MessageSquareText}
              title="Program Overview"
              description="Get an in-depth look at our sessions, speakers, and key topics throughout the workshop."
              bgCustom="bg-gradient-to-tr from-c4 to-c1"
              link="/program"
            />
          </ScrollRevealWrapper>
          <ScrollRevealWrapper delay={100}>
            <FeatureCard
              Icon={Landmark}
              title="Workshop Venue"
              description="Explore our workshop venue, designed to foster learning and collaboration."
              bgCustom="bg-gradient-to-tr from-c1 to-c2"
              link="/workshop-venue"
            />
          </ScrollRevealWrapper>
          <ScrollRevealWrapper delay={200}>
            <FeatureCard
              Icon={LayersIcon}
              title="Sponsorship Information"
              description="Discover sponsorship opportunities that support the workshop and your brand."
              bgCustom="bg-gradient-to-tr from-c2 to-c3"
              link="/sponsorship-info"
            />
          </ScrollRevealWrapper>
          <ScrollRevealWrapper delay={300}>
            <FeatureCard
              Icon={MessageCircleQuestion}
              title="Contact Us"
              description="Reach out to our team for questions or assistance with your workshop experience."
              bgCustom="bg-gradient-to-tr from-c3 to-c5"
              link="/contact"
            />
          </ScrollRevealWrapper> */}