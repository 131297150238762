const pageData = {
    "/": {
      backgroundImage: "/images/koala-banner1.webp",
      heading: `Welcome to the<br/>22nd International<br/>Vasculitis Workshop`,
    },
    "/accommodation/": {
      heading: "Accommodation",
      backgroundImage: "/images/banner2.webp",
    },
    "/destinations/": {
      heading: "Destinations",
    },
    "/latest-news/": {
      heading: "Latest News",
    },
    "/promotional-toolkit/": {
      heading: "Promotional Toolkit",
    },
    "/workshop-venue/": {
      heading: "Workshop Venue",
      backgroundImage: "/images/banner3.webp",
    },
    "/melbourne-bucket-list/": {
      heading: "Melbourne Bucket List",
      backgroundImage: "/images/banner3.webp",
    },
    "/visit-victoria/": {
      heading: "Visit Victoria",
      backgroundImage: "/images/banner3.webp",
    },
    "/amazing-australasia/": {
      heading: "Amazing Australasia",
      backgroundImage: "/images/banner3.webp",
    },
    "/transport-tips/": {
      heading: "Transport Tips",
      backgroundImage: "/images/banner3.webp",
    },
    "/visa-information/": {
      heading: "Visa Information",
      backgroundImage: "/images/banner3.webp",
    },
    "/program/": {
      heading: "Program Overview",
    },
    "/program-full/": {
      heading: "Program",
    },
    "/registration/": {
      heading: "Registration",
    },
    "/call-forabstracts/": {
      heading: "Call for Abstracts",
    },
    "/local-organising-committee/": {
      heading: "Local Organising Committee",
    },
    "/international-scientific-advisory-committee/": {
      heading: "International Scientific Advisory Committee",
    },
    "/sustainability/": {
      heading: "Sustainability",
    },
    "/loc-melbourne-guides/": {
      heading: "LOC Melbourne Guides",
    },
    "/previous-international-vasculitis-workshops/": {
      heading: "Previous International Vasculitis Workshops",
    },
    "/sponsorship-info/": {
      heading: `Sponsorship<br/>Information & Prospectus`,
      backgroundImage: "/images/banner2.webp",
    },
    "/sponsors-and-exhibitors/": {
      heading: "Sponsors & Exhibitors",
      backgroundImage: "/images/banner2.webp",
    },
    "/privacy-policy/": {
      heading: "Privacy Policy",
    },
    "/demo/": {
      heading: "Download Form",
    },
    "/contact/": {
      heading: "Contact Us",
    },
  };

  export const getPageData = (path) => {
    const defaultData = {
      backgroundImage: "/images/banner1.webp",
    };

    const data = pageData[path] || {};

    return {
      ...defaultData,
      ...data,
    };
  };
