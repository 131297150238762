import React from "react";
import { useLocation } from '@reach/router';
import { Link } from "gatsby";
import { menuItems } from "@/data/menuItems";
import UserForm from '@/components/form/EOI-Form';
import { Facebook, Linkedin, Instagram, Twitter } from 'lucide-react';
import { KeyDates } from "@/components/KeyDates";
import { StaticImage } from "gatsby-plugin-image";
import config from '@/utils/config';
import ScrollRevealWrapper from '@/components/utility/ScrollRevealWrapper';

const iconMap = {
  facebook: <Facebook strokeWidth={1} size={30} />,
  linkedin: <Linkedin strokeWidth={1} size={30} />,
  instagram: <Instagram strokeWidth={1} size={30} />,
  twitter: <Twitter strokeWidth={1} size={30} />,
};

const FooterLinkSection = ({ label, items }) => (
  <div className="space-y-4">
    <h4 className="text-lg font-bold mt-0">{label}</h4>
      <ul className="space-y-2 text-gray-300">
        {items.map((link, index) => (
          <li key={index}>
            <Link to={link.to}>{link.label}</Link>
          </li>
        ))}
      </ul>
  </div>
);

export default function Footer() {
  const eventName = 'IVW 2026';
  const location = useLocation();
  const currentPath = location.pathname;

  const normalizePath = (path) => (path.endsWith('/') ? path : `${path}/`);
  const normalizedCurrentPath = normalizePath(currentPath);
  const normalizedCustomPages = config.sidebar.customPages.map(normalizePath);

  const isSidebarActiveForPage =
    config.sidebar.enabled &&
    normalizedCustomPages.includes(normalizedCurrentPath);

  const shouldRenderFooterSection = !isSidebarActiveForPage;

  return (
    <footer>
      {shouldRenderFooterSection && (
      <section className="bg-gradient-to-r from-c3 to-c5" id="key-dates">
        <div className="container px-4 md:px-6 py-12 md:py-16">
          <h3 className="text-center mt-0 mb-10 text-white">Key Dates</h3>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 text-white">
            <KeyDates />
          </div>
        </div>
      </section>
      )}

      <section className="bg-gradient-to-r from-c1 to-c2">
        <div className="container px-4 md:px-6 pt-12 md:pt-16">
          <div className="flex items-center justify-center w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center w-full rounded-lg">
              <ScrollRevealWrapper>
                <div className="pb-10 lg:pb-0 lg:pr-8 border-b lg:border-b-0 border-r-0 lg:border-r border-gray-500" id="mailing-list">
                  <h3 className="mb-4 text-white">Join the Mailing List</h3>
                  <UserForm eventName={eventName} />
                </div>
              </ScrollRevealWrapper>
              <ScrollRevealWrapper delay={250}>
                <div className="lg:pl-8 pt-8 lg:pt-0" id="aoc">
                  <div>
                    <h3 className="text-white">Acknowledgement of Country</h3>
                    <p className="text-slate-300">We acknowledge the Traditional Owners of the lands and waters throughout Australia and pay respect to the Elders past, present and emerging. We recognise the importance of connection to culture, land, kinship and community to the health and wellbeing of Aboriginal & Torres Strait Islander families. We acknowledge the cultural practices and traditions still carried out today and being passed down to future generations.</p>
                    <p className="text-slate-300">We also recognise and celebrate the richness of cultures and traditions from Aotearoa, the Pacific Islands, and people from all corners of the world. We honor the diversity of backgrounds, experiences, and identities that make up our global community.</p>
                  </div>
                  <div className="flex gap-3 items-center mt-6">
                    <StaticImage
                      src="../images/indigenous-flag.webp"
                      alt="Indigenous Flag"
                      width={150}
                      loading="lazy"
                      placeholder="none"
                      formats={["auto", "webp"]}
                    />
                    <StaticImage
                      src="../images/torres-stait.webp"
                      alt="Torres Strait Flag"
                      width={150}
                      loading="lazy"
                      placeholder="none"
                      formats={["auto", "webp"]}
                    />
                  </div>
                </div>
              </ScrollRevealWrapper>
            </div>
          </div>

          <div className="py-12 mt-10 grid grid-cols-2 lg:grid-cols-5 gap-8 text-white border-y border-gray-500">
            {menuItems.footer.map((section, index) => (
              <ScrollRevealWrapper key={index} delay={100 * index}>
                <FooterLinkSection label={section.label} items={section.items} />
              </ScrollRevealWrapper>
            ))}
          </div>

          <div className="text-center">
            <div className="flex flex-col lg:flex-row justify-between gap-3 py-6">
              <div className="order-2 lg:order-1 text-slate-300">© {new Date().getFullYear()} <a href="https://icmsaust.com.au/" target="_blank" rel="noopener noreferrer" className="text-dteal">ICMSA</a>. All rights reserved.</div>
              {/* <div className="flex flex-row gap-3 order-1 lg:order-2 justify-center items-end">
                {menuItems.social.map((social, index) => (
                  <a
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    className="text-slate-300 hover:text-dteal flex items-center"
                    aria-label={social.screenReader}
                  >
                    {iconMap[social.name.toLowerCase()]}
                    <span className="sr-only">{social.screenReader}</span>
                  </a>
                ))}
              </div> */}
            </div>
          </div>

        </div>
      </section>

    </footer>
  );
}
